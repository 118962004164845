@import url(../css/pages/login-register-lock.css);

body{
    background-color: #fff!important;
  }
  
  /* .login-box{
    width: 500px!important;
  } */
  
  .login-register{
    padding:0px!important
  }
  
  .appLogo{
    width: 65%;
    margin-bottom: 20px;
  }
  
  .form-horizontal-appBoxShadow{
    padding: 70px 20px 100px;
    border: 1px solid #efefef;
    box-shadow: 2px 4px 10px 0px #efefef;
  }
  
  .btn-appColor{
      color: #fff;
      background-color: #c46977;
      border-color: #c46977;
  }

  .btn-appColor:hover {
    color: #fff;
    background-color: #904853;
    border-color: #904853;
}
  
  .text-appColor{
    color: #c46977 !important;
  }
  