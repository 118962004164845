

  .system-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            /* min-width: 0vw; */
            height: 100vh;
            overflow-y: auto;
}


.system-wrapper {
  max-height: 80vh;
    background-color: #f0f0f0; 
           /* margin: 0; */
            padding: 20px;
         
}

.centered {
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.system-viewer {
    /* zoom: 5; */
    /* display: flex; */
   
    /* min-height: 50vh; */
    max-height: 800px;
    /* max-width: 600px; */
    min-width: 600px;
  }
  
  .system-title {
    background-color: #f0f0f0;
    margin-top: -10px;
    padding-left: 10px;
    padding-right: 10px;
}

  
  .paper {
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* border: 1px solid #ccc; */
    width: 100%;
    height: auto;
    text-align: center; /* Change 'center' to 'right' or 'left' as needed */
  }
  
  .btnoption {
    min-width: 120px !important;
    max-width: 120px;
  }