.wrapper {
  flex-grow: 1;
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}



.label {
  font-size: 1rem;
  font-weight: 500;
}

.editor {
  height: 500px;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.btn {
  border: none;
  color: #fff;
  border-radius: 20px;
  padding: 0.6rem 2rem;
  background-color: royalblue;
}
