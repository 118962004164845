.editor-image {
    position: relative;
    width: 100%;
  }
  

  
  .toolbar-content {
    pointer-events: auto !important; 
  }
  
  
  
  .editor-image:hover .bottom-overlay {
    opacity: 1;
    animation: fadeOut 0.5s linear 5s forwards;

  }
  
  .cursor-zoom-in {
    cursor: zoom-in !important; /* Set cursor to zoom icon */
  }
  
  .cursor-zoom-out {
    cursor: zoom-out !important; /* Set cursor to zoom icon */
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  /* .bottom-overlay:hover { */
    /* opacity: 1; */
    /* pointer-events: auto;  */
/*  */
  /* } */
  
  /* Auto-hide after 5 seconds */
  /* .bottom-overlay:not(:hover) { */
    /* animation: fadeOut 0.5s linear 5s forwards; */
  /* } */
  /*  */
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }