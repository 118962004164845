@page {
    background-color: white;
    margin: 5%;
    overflow: hidden !important;
 }

.graph-view-m {
    width: 100% !important;
    overflow-y: auto !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

.statistics-table {
        width: 100%;
        background-color: #E0E0E0;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: center;

        /* overflow-y: scroll; */
        
}

.bsccpActions  {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
}

@media print {
    .statistics-table {
        background-color: white !important;
        width: 100% ;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        overflow-y: visible;
}
      
    .bsccpActions * {
        background-color: black;
        display: none !important;
    }
    
    .graph-view {
        width: 1000px;
    }

    .card-body {
        min-height: 100% !important;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}